import React, { useContext, useEffect } from "react";
import { CountryContext } from "./MainComponent";
import Loading from "./Layout/loader";

const SubdomainRedirect = () => {
  const { store } = useContext(CountryContext);
  const host = window.location.hostname;

  useEffect(() => {
    const handleRedirect = (url: any) => {
      window.location.href = url;
    };

    if (host.includes("localhost")) {
      handleRedirect(`/${store}`);
      return;
    }

    if (host.includes("react.") || host.includes("react-")) {
      if (store === "in" && !host.includes("react-in")) {
        handleRedirect(`http://react-in.jebelz.com/${store}`);
      } else if (store === "sa_ar" && !host.includes("react-sa")) {
        handleRedirect(`https://react-sa.jebelz.com/${store}`);
      } else if (store === "ar" && !host.includes("react-om")) {
        handleRedirect(`https://react-om.jebelz.com/${store}`);
      } else {
        handleRedirect(`https://react.jebelz.com/${store}`);
      }
    } else {
      if (store === "in" && !host.includes("in")) {
        handleRedirect(`https://in.jebelz.com/${store}`);
      } else if (store === "sa_ar" && !host.includes("sa")) {
        handleRedirect(`https://sa.jebelz.com/sa_ar`);
      } else if (store === "ar" && !host.includes("om")) {
        handleRedirect(`https://om.jebelz.com/${store}`);
      } else {
        handleRedirect(`https://jebelz.com/${store}`);
      }
    }
  }, [store, host]);

  return <><Loading /></>;
};

export default SubdomainRedirect;
